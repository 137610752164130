import React from 'react';
import './index.less'
import { Progress } from 'antd'

const IconFont = Loader.loadBaseComponent("IconFont");
class InfoIconItemView extends React.Component {
  render() {
    let {icon,title,num,percent,click} = this.props
    return(
      <div className="info-icon-progess-view" onClick={click}>
        < div className = "info-icon-progess-content" >
          <div className="info-item-icon">
            <IconFont type={icon} />
          </div>
          <div className="info-item-num primary-color">
            {Utils.thousand(num)}
          </div>
        </ div>
        <Progress strokeLinecap="square" type="circle" width={100} strokeColor={'#fa0'} strokeWidth={10} percent={percent *100} />
        <p className='info-item-title'>{title}</p>
      </div>
    ) 
  }
}

export default InfoIconItemView